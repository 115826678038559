function findInjectEventResponse(events, eventId, responseObj) {
  for (const ev of events) {
    if (ev.id == eventId) {
      ev.userResponse = responseObj; // inject user response to event obj
      return true;
    }
  }
  return false;
}
function findInjectABSSessionResponse(allIntakes, eventId, responseObj) {
  for (const intake of allIntakes) {
    for (const ev of intake.sessions) {
      if (ev.id == eventId) {
        ev.userResponse = responseObj; // inject user response to event obj
        return true;
      }
    }
  }
  return false;
}

export default {
  // Work Events (Seminars / Visits)
  receiveUserEventResponses(state, data) {
    for (const row of data) {
      findInjectEventResponse(state.sessions, row.sessionId, row);
      findInjectABSSessionResponse(state.absIntakes, row.sessionId, row);
    }
  },
  injectUserEventResponse(state, responseObj) {
    const idx = state.user.sessionResponses.findIndex(r => r.sessionId == responseObj.sessionId);
    if (idx !== -1) {
      state.user.sessionResponses.splice(idx, 1, responseObj);
    } else {
      state.user.sessionResponses.push(responseObj);
    }
    findInjectEventResponse(state.sessions, responseObj.sessionId, responseObj); // Work Seminars / Visits
    findInjectABSSessionResponse(state.absIntakes, responseObj.sessionId, responseObj); // AchieveBot Spark
  },
  
  // Form Questions Responses
  receiveUserFormResponses(state, data) {
    state.userFormResponses = data;
    state.loadingUserFormResponses = false;
  },
  // Form Questions
  upsertUserFormResponses(state, data) {
    data.forEach(response => {
      const { sessionId, taskId, questionId } = response;
      const idx = state.userFormResponses.findIndex(r => r.sessionId == sessionId && r.taskId == taskId && r.questionId == questionId);
      if (idx !== -1) {
        state.userFormResponses.splice(idx, 1, response);
      } else {
        state.userFormResponses.push(response);
      }
    });
  },
}