export default {
  /**
   * User Credentials
   */
  updateUserCredentials(state, updatedCredentials) { // mainly for updating the order of user credentials
    for (const credential of updatedCredentials) {
      const idx = state.userCredentials.findIndex(uc => uc.id == credential.id);
      if (idx !== -1) state.userCredentials.splice(idx, 1, credential);
    }
  },
  receiveUserCredentials(state, data) {
    state.userCredentials = data.userCredentials;
    state.userSections = data.userSections;
    state.loadingCredentials = false;
  },
  upsertUserCredential(state, credential) {
    const idx = state.userCredentials.findIndex(uc => uc.id == credential.id);
    if (idx !== -1) {
      state.userCredentials.splice(idx, 1, credential);
    } else {
      state.userCredentials.push(credential);
    }
  },
  addUserCredentials(state, credentials) {
    state.userCredentials = state.userCredentials.concat(credentials);
  },
  deleteUserCredential(state, targetCredentialId) {
    const idx = state.userCredentials.findIndex(uc => uc.id == targetCredentialId);
    state.userCredentials.splice(idx, 1);
  },

  /**
   * User Credential Sections
   */
  updateUserSections(state, updatedUserSections) { // mainly for updating the order of user credentials
    for (const section of updatedUserSections) {
      const idx = state.userSections.findIndex(s => s.id == section.id);
      if (idx !== -1) state.userSections.splice(idx, 1, section);
    }
  },
  upsertUserSection(state, userSection) {
    const idx = state.userSections.findIndex(s => s.id == userSection.id);
    if (idx !== -1) {
      state.userSections.splice(idx, 1, userSection);
    } else {
      state.userSections.push(userSection);
    }
  },
  deleteUserSection(state, userSectionId) {
    const idx = state.userSections.findIndex(s => s.id == userSectionId);
    state.userSections.splice(idx, 1);
    state.userCredentials = state.userCredentials.filter(cr => cr.userSectionId != userSectionId); // delete the bullet points as well
  }
}