import PortalService from '@/services/PortalService';
import JobEXService from '@/services/JobEXService';
import CommonService from '@/services/CommonService';
import TeacherService from '@/services/TeacherService';
import UserService from '@/services/UserService';
import AuthService from '@/services/AuthService';

export const setUserJoinedWAGroup = ({ state, commit }) => {
  if (state.user.userInWaGroup != true) {
    const updatingObj = {
      userInWaGroup: true,
    }
    commit('updateUser', updatingObj);
    UserService.updateUser(updatingObj);
  }
}

export const resetUserData = ({ state, getters, commit }) => {
  commit('receiveUser', {}); // empty the store user object
}

// Mainly used in switching school (admin)
export const getStep1Questions = ({ state, commit }, programId) => {
  CommonService.getStep1Questions(state.user.schoolId, programId).then(res => {
    commit('upsertStep1Questions', res.step1Questions);
  })
}

// Mainly used in switching program (admin)
export const getUniProgramData = ({ state, commit }, programId) => {
  const { user, onetimeAccessInfo, } = state;
  PortalService.getProgram(programId).then(program => {
    commit('receiveProgram', program);
  });
  if (!onetimeAccessInfo && !user.schoolId) {
    JobEXService.getSavedCareerPlan().then(plan => {
      commit('receiveUserCareerPlan', plan);
    });
  }
}

export const getPortalData = ({ state, getters, commit }, programId) => {
  const { user, onetimeAccessInfo, } = state;
  CommonService.getPortalData(user.schoolId, programId).then(portalData => {
    commit('receivePortalData', portalData);
    if (programId) {
      PortalService.getProgram(programId).then(program => {
        commit('receiveProgram', program);
      });
      if (!onetimeAccessInfo && !user.schoolId) {
        JobEXService.getSavedCareerPlan().then(plan => {
          commit('receiveUserCareerPlan', plan);
        });
      }
    }
  });
}

export const getProgramUsers = ({ state, getters, commit }, programId) => {
  if (programId) {
    JobEXService.getUsersByProgramId(programId).then(res => {
      commit('receiveProgramUsers', res);
    });
  } else {
    commit('receiveProgramUsers', []);
  }
}
export const getSchoolUsers = ({ state, getters, commit }, schoolId) => {
  if (schoolId) {
    TeacherService.getUsersBySchoolId(schoolId).then(res => {
      commit('receiveSchoolUsers', res);
    });
  } else {
    commit('receiveSchoolUsers', []);
  }
}

export const handleOnetimeAccessTokenInfo = ({ state, getters, commit, dispatch }, info) => {
  //localStorage.setItem("sessionAccessToken", info.token);
  dispatch('getPortalData', info.programId);
  commit('receiveOnetimeAccessInfo', info);
  let role = info.programId ? "university-student" : "teacher";
  let teacher = {};
  if (info.programId && info.schoolId) {
    role = "secondary-school-student"; // e.g. PolyU SN: Mock JUPAS demo
    teacher = null;
  }
  commit('receiveUser', {
    roles: role,
    programId: info.programId,
    schoolId: info.schoolId,
    showStep1: true,
    showStep2: true,
    showStep3: true,
    loggedInWithDemoLink: true,
    teacher,
  });
}

export const checkOnetimeGetUserInfo = ({ state, getters, commit, dispatch }, phone) => {
  AuthService.checkPhoneExists(phone).then(existingFirebaseUser => {
    if (existingFirebaseUser && existingFirebaseUser.uid) {
      commit('setLoadingUser', true);
      UserService.getUserById(existingFirebaseUser.uid).then(res => {
        if (res) {
          // Commit User Object
          commit('receiveUser', res);

          // Teacher User
          if (res.teacher && res.teacher.userId) {
            dispatch('getSchoolUsers', res.schoolId);
          }

          // Get portal data after login
          dispatch('getPortalData', res.programId);
        } else {
          commit('setLoadingUser', false);
        }
      });
    }
  })
}