export default {
  // CL claims
  upsertUserCLClaims(state, { userClaims, userExps }) {
    userClaims.forEach(uc => {
      const idx = state.user.clClaims.findIndex(c => c.id == uc.id);
      if (idx !== -1) {
        state.user.clClaims.splice(idx, 1, uc);
      } else {
        state.user.clClaims.push(uc);
      }
    });
    userExps.forEach(ue => {
      const idx = state.user.clExps.findIndex(e => e.id == ue.id);
      if (idx !== -1) {
        state.user.clExps.splice(idx, 1, ue);
      } else {
        state.user.clExps.push(ue);
      }
    })
  },

  // Career plan
  receiveUserCareerPlan(state, plan) {
    state.userCareerPlan = plan && plan.data ? JSON.parse(plan.data) : {};
  },
  updateUserCareerPlan(state, plan) {
    if (state.userCareerPlan) {
      state.userCareerPlan.orderedProfessions = plan.orderedProfessions;
      state.userCareerPlan.targetSegments = plan.targetSegments;
      state.userCareerPlan.targetEmployers = plan.targetEmployers;
      state.userCareerPlan.claim1 = plan.claim1;
      state.userCareerPlan.claim2 = plan.claim2;
      state.userCareerPlan.claim3 = plan.claim3;
    }
  },
}