const findInjectProfessionResponse = (professions, professionId, response) => {
  for (const p of professions) {
    if (p.id == professionId) {
      p.userResponse = response; // inject user response to event obj
      return true;
    }
  }
  return false;
}

export default {
  // Profession Survey
  injectUserProfessionResponses(state, responseObjs) {
    if (responseObjs.length > 0) {
      for (const responseObj of responseObjs) {
        findInjectProfessionResponse(state.allProfessions, responseObj.professionId, responseObj.response);
      }
      state.submittedProfessionResponses = true;
    }
  },
}