import firebase from "firebase/compat/app";
import { Capacitor } from "@capacitor/core";
import 'firebase/compat/analytics';

import { initializeAuth, getAuth ,indexedDBLocalPersistence,
        signInWithCredential, signInWithPhoneNumber, signInWithEmailAndPassword,
        sendPasswordResetEmail, signInWithCustomToken, updatePhoneNumber,
        reauthenticateWithCredential, updatePassword, linkWithCredential, } from 'firebase/auth';

import config from '@/config';
const firebaseApp = firebase.initializeApp(config.Firebase);
firebase.analytics();

export const auth = (Capacitor.isNativePlatform() ? initializeAuth(firebaseApp, { persistence: indexedDBLocalPersistence }) : getAuth());

export const credentialSignIn = async (credential) => {
  return await signInWithCredential(auth, credential);
}

export const phoneSignIn = async (verifyingPhone, appVerifier) => {
  return await signInWithPhoneNumber(auth, verifyingPhone, appVerifier);
}

export const emailSignIn = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
}

export const sendPswResetEmail = async (email) => {
  return await sendPasswordResetEmail(auth, email);
}

export const customTokenSignin = async (customToken) => {
  return await signInWithCustomToken(auth, customToken);
}

export const updateUserPhoneNumber = async (user, credential) => {
  return await updatePhoneNumber(user, credential);
}

export const reauthUserWithCredential = async (user, credential) => {
  return await reauthenticateWithCredential(user, credential);
}

export const updateUserPassword = async (user, newPassword) => {
  return await updatePassword(user, newPassword);
}

export const linkUserWithCredential = async (user, credential) => {
  return await linkWithCredential(user, credential);
}